<template>
	<div>
		<div class="form-group">
			<label for="cardNumber">Card Number</label>
			<div id="cardNumber" class="form-control"></div>
		</div>
		<div class="settings-item__form-groups">
			<div class="form-group">
				<label for="cardExpiry">Expiration Date</label>
				<div id="cardExpiry" class="form-control"></div>
			</div>
			<div class="form-group">
				<label for="cardCVC">CVC</label>
				<div id="cardCVC" class="form-control"></div>
			</div>
		</div>
		<div v-if="cardError" id="card-error">{{cardError}}</div>
	</div>

</template>

<script>
    export default {
        name: "CardForm",
		props: {
            cardError : {
                type: String,
				default: null
			}
		}
    }
</script>

<style scoped>
	#card-error {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
		margin-bottom: 10px;
	}
</style>
