
<template>
	<b-modal :id="modalId" :key="`key_${modalId}`" :modal-class=" `${classNames}`"
			 :title="title"
			 :ok-title="submitButtonText"
			 :cancel-title="cancelButtonText"
			 cancel-variant="outline-secondary"
			 :ok-only="!isCloseModalButtonShown"
			 :hide-footer="hideButtons"
			 title-tag="h2"
			 :hide-header="hideTitle"
			 :scrollable="scrollable"
			 :size="size"
			 :no-close-on-backdrop="noCloseOnBackdrop"
			 @ok="handleOk"
			 @hidden="handleHidden">
		<div slot="modal-header-close">
			<i class="icon-close"></i>
		</div>
		<slot name="default"></slot>

	</b-modal>
</template>


<script>

    export default {
        name: 'app-modal',
        props: {
            modalId: {
                type: String,
                required: true
            },
            submitButtonText: {
                type: String,
                required: false
            },
			cancelButtonText: {
                type: String,
                required: false
            },
            noCloseOnBackdrop: {
                type: Boolean,
                required: false,
                default: false
            },
            title: {
                type: String,
                required: false
            },
            isCloseModalButtonShown: {
                type: Boolean,
                required: false,
                default: true
            },
            hideButtons: {
                type: Boolean,
                required: false,
                default: false
            },
            hideTitle: {
                type: Boolean,
                required: false,
                default: false
            },
            scrollable: {
                type: Boolean,
                required: false,
                default: false
            },
            size: {
                type: String,
                required: false,
                default: 'lg'
            },
            handleOk: {
                type: Function,
                required: false,
                default: () => {}
			},
            handleHidden: {
                type: Function,
                required: false,
				default: () => {}
			},
			classNames: {
                type: String,
				default: ''
			}
        },

        methods: {
            hideModal() {
                this.$root.$emit('bv::hide::modal', this.modalId, '#btnShow')
            },
            onSubmit() {
                this.$emit('send');
                this.hideModal();
            }
        }
    }
</script>

