<template>
	<div class="pp-faq">
		<h2>Frequently Asked Questions - FAQ</h2>
		<div class="row">
			<div class="col-lg-3 offset-lg-3 col-md-6">
				<div class="pp-faq__item">
					<h3 class="pp-faq__item-title">What is Lead Generation Tool ?</h3>
					<div class="pp-faq__item-txt">
						<p>Put the website audit tool on your website to let visitors do the website audits and you generate quality leads. This tool is the most cost-effective and generates immediate results. Generate more quality leads from day one.</p>
					</div>
				</div>
				<div class="pp-faq__item">
					<h3 class="pp-faq__item-title">How does the 14-day free trial work?</h3>
					<div class="pp-faq__item-txt">
						<p>After you signup, you get access to all the features in the Site Auditor Agency Plan for 14 days. We want to make sure that you can get Site Auditor working to your satisfaction before we ask you for your credit card.</p>
					</div>
				</div>
				<div class="pp-faq__item">
					<h3 class="pp-faq__item-title">Do you have any cancellation fees?</h3>
					<div class="pp-faq__item-txt">
						<p>No. Site Auditor is a pay-as-you-go service. We do not have any cancellation fees. You can cancel whenever you want. If you cancel, you’ll be billed for the current month, but you won’t be billed again.</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="pp-faq__item">
					<h3 class="pp-faq__item-title">What is White Label PDF Download?</h3>
					<div class="pp-faq__item-txt">
						<p>Send a comprehensive and personalized (your logo, header, footer etc) website audit report to your clients. Save your team’s numerous hours of report preparation. Get your own website audit report in just seconds.</p>
					</div>
				</div>
				<div class="pp-faq__item">
					<h3 class="pp-faq__item-title">Who owns my data?</h3>
					<div class="pp-faq__item-txt">
						<p>You do! It’s your data after all! We want you to be with Site Auditor because you love using the product, not because your data is stuck in it. You can export all your information from Site Auditor any time you need, in any plan.</p>
					</div>
				</div>
				<div class="pp-faq__item">
					<h3 class="pp-faq__item-title">Have any other questions?</h3>
					<div class="pp-faq__item-txt">
						<p>If you have questions about Site Auditor or the signup process, Please email us at <a class="blue-link" href="mailto:support@siteauditor.com">support@siteauditor.com</a> and we will be glad to answer all your questions</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "Faq"
    }
</script>

<style scoped>

</style>
