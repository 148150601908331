<template>
	<div>
		<PageHeader title="Pick Plan"/>
		<section class="pick-plan">
			<div class="container">
				<div class="pp-options">
					<div class="row" v-if="dataLoaded && currentPlanDataLoaded">
						<div class="col-lg-8 offset-lg-2">
							<div class="pp-options__items">
								<div class="pp-options__item pp-options__item--small">
									<p class="pp-options__item-subtitle">{{plans.monthly.name}}</p>
									<h3 class="pp-options__item-title">${{plans.monthly.price}}</h3>
									<p class="pp-options__item-txt">Per month</p>
									<a v-if="canSelectMonthly" class="btn btn-primary"
									   @click="selectPlan(plans.monthly)">Select</a>
									<a v-else class="btn btn-secondary disabled" href="#">{{!plans.monthly.is_current  ? 'Select' : 'Your Plan'}}</a>
								</div>
								<div class="pp-options__item pp-options__item--huge">
									<p class="pp-options__item-subtitle">{{plans.yearly.name}} <span
											class="badge bg-success">Save 20%</span></p>
									<h3 class="pp-options__item-title">${{plans.yearly.price / 12}}</h3>
									<p class="pp-options__item-txt">Per month <span class="badge bg-info">${{plans.yearly.price}} billed yearly</span>
									</p>
									<a v-if="canSelectYearly" class="btn btn-primary" href="#"
									   @click="selectPlan(plans.yearly)">Select</a>
									<a v-else class="btn btn-secondary disabled" href="#">{{!plans.yearly.is_current ? 'Select' : 'Your Plan'}}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pp-features">
					<h2>All Features Unlimited</h2>
					<div class="row">
						<div class="col-lg-3 offset-lg-3 col-md-6 pp-features__item">
							<ul class="pp-features__list">
								<li v-for="item in features" :key="`${item}-monthly`" class="pp-features__list-item">
									{{item}}
								</li>

							</ul>
						</div>
						<div class="col-lg-3 col-md-6 pp-features__item">
							<ul class="pp-features__list">
								<li v-for="item in features" :key="`${item}-yearly`" class="pp-features__list-item">
									{{item}}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Faq/>

			</div>
		</section>

		<Modal v-if="isOnTrial" modal-id="select-plan"
			   classNames="modal--plan modal--pay"
			   :hideButtons="true"
			   :handleHidden="destroyStripeElements">
			<div class="modal-logo"><img src="../../../../../shared/assets/styles/images/logo.svg" alt=""></div>
			<form class="modal-form settings-item__form" action="#">
				<h2>Monthly Plan</h2>
				<CardForm/>

				<div v-if="cardError" id="card-error">{{cardError}}</div>

				<input v-if="selectedPlan" class="btn btn-primary" type="submit" @click="createToken"
					   :disabled="paymentButtonDisabled"
					   :value="`Charge $${this.selectedPlan.price} ${jsUcfirst(this.selectedPlan.interval)}`">
				<div class="modal-links"><a class="blue-link" @click="closePlanModal">Cancel</a></div>
			</form>

		</Modal>

		<Modal  v-if="!isOnTrial" modal-id="upgrade-plan"
			   classNames="modal--plan modal--pay"
			   :hideButtons="true">
			<div class="modal-logo"><img src="../../../../../shared/assets/styles/images/logo.svg" alt="">
			</div>
			<form class="modal-form settings-item__form" @submit.prevent="updateSubscription(selectedPlan.id)">
				<div class="modal-info">
					<h2>Change to {{selectedPlan.name}} Plan</h2>
					<p class="modal-info__txt">Card on file:</p>
					<ul class="modal-info__list">
						<li class="modal-info__item">
							<span class="modal-info__item-title">Card Type:</span>
							<span class="modal-info__item-value">{{card.brand}}</span>
						</li>
						<li class="modal-info__item">
							<span class="modal-info__item-title">Last 4 digits: </span>
							<span class="modal-info__item-value">{{card.last4}}</span>
						</li>
						<li class="modal-info__item">
							<span class="modal-info__item-title">Expiration Date:</span>
							<span class="modal-info__item-value">{{cardExpirationDate}}</span>
						</li>
					</ul>
					<div class="modal-info__link"><a class="blue-link" @click="openUpdateCardModal">
						<i class="svg-image-card"></i> Update Card</a>
					</div>
				</div>
				<input class="btn btn-primary" type="submit" value="Change Plan">
				<div class="modal-links"><a class="blue-link" @click="closeUpdatePlanModal">Cancel</a></div>
			</form>

		</Modal>

		<Modal v-if="!isOnTrial" modal-id="upgrade-plan-card"
			   classNames="modal--plan modal--pay"
			   :hideButtons="true"
			   :handleHidden="destroyStripeElements">
			<div class="modal-logo"><img src="../../../../../shared/assets/styles/images/logo.svg" alt="">
			</div>
			<form class="modal-form settings-item__form" action="#">
				<h2>Update Card</h2>
				<CardForm :card-error="cardError"/>
				<input class="btn btn-primary" :disabled="paymentButtonDisabled" @click="createToken" type="submit" value="Change Plan">
				<div class="modal-links"><a class="blue-link" @click="closeUpdateCardPopup">Cancel</a></div>
			</form>

		</Modal>
	</div>
</template>

<script>
    import PageHeader from "../../../../../shared/components/PageHeader";
    import Faq from "./components/Faq";
    import Modal from "../../../../../shared/components/Modal";
    import {PLAN_TYPE, SUBSCRIPTION_INTERVAL, SUBSCRIPTION_STATUS} from "../../../../../shared/constants";
    import CardForm from "./components/CardForm";

    export default {
        name: "PickPlan",
        components: {CardForm, Modal, Faq, PageHeader},
        data() {
            return {
                card: {
                    last4: '',
                    brand: '',
                    exp_month: '',
                    exp_year: ''
                },
                currentPlan: {
                    name: '',
                    display_name: '',
                },
                selectedPlan: {
                    name: '',
                    price: '',
                    id: ''
                },
                plans: {
                    monthly: null,
                    yearly: null,
                },
                dataLoaded: false,
                currentPlanDataLoaded : false,
                cardError: null,
                paymentButtonDisabled: true,
                token: null,
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
                features: [
                    'Unlimited Leads',
                    'Email Validation',
                    'Unlimited Downloads',
                    'Unlimited Audits',
                    'Unlimited Integrations',
                    'White Label URL',
                    'WordPress Plugin'
                ]
            }
        },
        mounted() {
            this.getAllPlans();
            this.$store.dispatch('subscription/getSubscription', this.domain)
                .then((response) => {
                    this.currentPlan = {
                        name: response.name,
                        description: response.description,
                        status: response.status,
                        date: response.expire_date,
                        planId: response.plan_id,
                        interval: response.interval,
                        price: response.price,
                        type: response.type
                    };
                    this.currentPlanDataLoaded = true;

                }).catch((err) => {

                this.showErrorNotifications(err);
            })
        },
        methods: {
            getAllPlans() {
                this.$store.dispatch('subscription/getPlans', this.domain)
                    .then((response) => {
                        this.plans = response;
                        this.dataLoaded = true;

                    }).catch((err) => {

                    this.showErrorNotifications(err);

                })
			},
            selectPlan(plan) {
                this.selectedPlan = plan;
                if (this.isOnTrial) {
                    this.$bvModal.show('select-plan');
                    this.$nextTick(() => {
                        this.setStripeElements();
                    })
                } else {
                    this.getCardData().then(() => {

                        if( this.card.last4) {
                            this.$bvModal.show('upgrade-plan');
						} else {
                            this.openUpdateCardModal();
						}

                    });
                }
            },
            openUpdateCardModal() {
                this.$bvModal.hide('upgrade-plan');
                this.$bvModal.show('upgrade-plan-card');
                this.$nextTick(() => {
                    this.setStripeElements();
                });
			},
            closeUpdatePlanModal() {
                this.$bvModal.hide('upgrade-plan');
                this.selectedPlan = {
                    name: '',
                    price: '',
                    id: ''
                };
			},
            closeUpdateCardPopup() {
                this.$bvModal.hide('upgrade-plan-card');
                this.selectedPlan = {
                    name: '',
                    price: '',
                    id: ''
                };
			},

            getCardData() {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('subscription/getCardDetails', this.domain)
                        .then((response) => {
                            this.card.last4 = response.last4;
                            this.card.brand = response.brand;
                            this.card.exp_month = response.exp_month;
                            this.card.exp_year = response.exp_year;
                            resolve(response);

                        }).catch((err) => {
                        this.showErrorNotifications(err);
                        reject(err)
                    })

                });
            },

            setStripeElements() {
                const style = {
                    base: {
                        color: '#4180c6',
                        fontSize: '13px',
                        lineHeight: '15px',
                        '::placeholder': {
                            color: '#828F9C',
                        },
                    },
                };
                this.cardNumber = this.stripeElements.create('cardNumber', {
                    style: style,
                    placeholder: 'xxxx xxxx xxxx xxxx'
                });
                this.cardNumber.mount('#cardNumber');
                this.cardExpiry = this.stripeElements.create('cardExpiry', {style: style, placeholder: 'DD/MM'});
                this.cardExpiry.mount('#cardExpiry');
                this.cardCvc = this.stripeElements.create('cardCvc', {style: style, placeholder: 'XXX'});
                this.cardCvc.mount('#cardCVC');
                this.$nextTick(() => {
                    this.paymentButtonDisabled = false;
                })
			},

            async createToken($event) {
                $event.preventDefault();
                await this.updateSubscription(this.selectedPlan.id, true);
            },

            async updateSubscription(planID, newPaymentMethod = false) {
                this.paymentButtonDisabled = true;
                this.cardError = null;
                try {
                    const stripeSubscription = await this.$store.dispatch('subscription/generate', {plan_id: planID});
                    const cardPaymentData = newPaymentMethod ? {
                        payment_method: { card: this.cardNumber }
                    } : undefined;
                    const result = await this.$stripe.confirmCardPayment(stripeSubscription.client_secret, cardPaymentData);
                    if (result.error) {
                        this.cardError = result.error.message;
                        this.paymentButtonDisabled = false
                        return;
                    }
                    await this.$store.dispatch('subscription/sync');
                    this.showNotifications({message: 'Subscription successfully updated', type: 'success'});
                    await this.$store.dispatch('auth/me');
                    this.$router.push({name: 'dashboard.settings.billing'});
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        this.cardError = error.response.data;
                    } else {
                        this.showErrorNotifications(error);
                    }
                }
                this.paymentButtonDisabled = false;
            },

            closePlanModal() {
                this.$bvModal.hide('select-plan');
                this.selectedPlan = null;
            },
            destroyStripeElements() {
                this.cardError = null;
                this.cardNumber.destroy();
                this.cardExpiry.destroy();
                this.cardCvc.destroy();
            },
            jsUcfirst(string) {
                if (string) {
                    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
				}

            }
        },
        computed: {
            cardExpirationDate() {
                if (this.card.exp_month) {
                    let month = this.card.exp_month < 10 ? '0' + this.card.exp_month : '' + this.card.exp_month;
                    let year = this.card.exp_year.toString().slice(-2);

                    return month + '/' + year;
                }
                return 'DD/MM';

            },
            stripeElements() {
                return this.$stripe.elements();

            },
            isOnTrial() {
                return this.currentPlan.type === PLAN_TYPE.FREE_TRIAL;
            },
            isOnYearly() {
                if (this.currentPlan.interval) {
                    return this.currentPlan.status === SUBSCRIPTION_STATUS.VALID && this.currentPlan.interval === SUBSCRIPTION_INTERVAL.YEARLY;
                }
                return true;

            },
            canSelectMonthly() {
                return this.isOnTrial || this.currentPlan.status === SUBSCRIPTION_STATUS.EXPIRED || (this.currentPlan.price < this.plans.monthly.price && this.currentPlan.plan_id !== this.plans.monthly.id);
            },

            canSelectYearly() {
                return this.isOnTrial || this.currentPlan.status === SUBSCRIPTION_STATUS.EXPIRED || (this.currentPlan.price < this.plans.yearly.price && this.currentPlan.plan_id !== this.plans.yearly.id);
            }
        },
    }
</script>

<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/pick-plan";
	@import "../../../../../shared/assets/styles/blocks/settings";
</style>

